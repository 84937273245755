import axios from "axios"
import { useState, useEffect } from "react"

function CreateUser() {
 const [name, setName] = useState("")
 const [email, setEmail] = useState("")
 const [password1, setPassword1] = useState("")
 const [password2, setPassword2] = useState("")
 const [passwordError, setPasswordError] = useState(false)

 function sendRequest(event) {
  event.preventDefault()

  axios
   .post(process.env.REACT_APP_BACKEND_URL + "/user/create", {
    name,
    email,
    password1,
    password2,
   })
   .then((res) => {
    alert(res.data.message)
   })
   .catch((error) => {
    alert(error)
    // console.log(error)
   })
 }

 useEffect(() => {
  if (password1 !== "" && password2 !== "") {
   if (password1 !== password2) {
    setPasswordError(true)
   } else {
    setPasswordError(false)
   }
  } else {
   setPasswordError(false)
  }
 }, [password1, password2])

 return (
  <div className="container">
   <div className="row justify-content-md-center">
    <div className="col-md-auto border rounded m-5 p-5">
     <h1>Create new user</h1>
     <form onSubmit={sendRequest}>
      <div className="mb-3">
       <label forhtml="inputName" className="form-label">
        User name
       </label>
       <input
        type="text"
        className="form-control"
        id="inputName"
        aria-describedby="nameHelp"
        value={name}
        required={true}
        onChange={(input) => setName(input.target.value)}
       />
      </div>
      <div className="mb-3">
       <label forhtml="inputEmail" className="form-label">
        Email address
       </label>
       <input
        type="email"
        className="form-control"
        id="inputEmail"
        aria-describedby="emailHelp"
        value={email}
        required={true}
        onChange={(input) => setEmail(input.target.value)}
       />
       <div id="emailHelp" className="form-text">
        We'll never share your email with anyone else.
       </div>
      </div>
      <div className="mb-3">
       <label forhtml="inputPassword1" className="form-label">
        Password
       </label>
       <input
        type="password"
        className="form-control"
        id="inputPassword1"
        autoComplete="on"
        value={password1}
        required={true}
        onChange={(input) => setPassword1(input.target.value)}
       />
      </div>
      <div className="mb-3">
       <label forhtml="inputPassword2" className="form-label">
        Repeat your password
       </label>
       <input
        type="password"
        className="form-control"
        id="inputPassword2"
        autoComplete="on"
        value={password2}
        required={true}
        onChange={(input) => setPassword2(input.target.value)}
       />
      </div>
      {passwordError && (
       <div class="alert alert-danger" role="alert">
        Passwords do not match!!
       </div>
      )}
      <button
       type="submit"
       className="btn btn-primary"
       disabled={passwordError || password1 === "" || password2 === ""}
      >
       Submit
      </button>
     </form>
    </div>
   </div>
  </div>
 )
}

export default CreateUser
