import { Link } from "react-router-dom"
import axios from "axios"
import { useEffect } from "react"

function NavBar({ logout }) {
 async function logoutLoc() {
  localStorage.removeItem("token")
  logout()
  await axios.get(process.env.REACT_APP_BACKEND_URL + "/logout")
 }

 async function checkLogged() {
  await axios
   .get(process.env.REACT_APP_BACKEND_URL + "/check")
   .catch(function (error) {
    if (error.response) {
     if (error.response.status === 401) {
      logoutLoc()
     }
    }
   })
 }

 useEffect(() => {
  checkLogged()
 }, [])

 return (
  <nav className="navbar navbar-expand-lg navbar-light bg-light">
   <div className="container-fluid">
    <Link to="/" className="navbar-brand">
     PAV-ADMIN
    </Link>
    <button
     className="navbar-toggler"
     type="button"
     data-bs-toggle="collapse"
     data-bs-target="#navbarText"
     aria-controls="navbarText"
     aria-expanded="false"
     aria-label="Toggle navigation"
    >
     <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarText">
     <ul className="navbar-nav me-auto mb-2 mb-lg-0">
      <li className="nav-item">
       <Link to="/pages" className="nav-link" aria-current="page">
        Page admin
       </Link>
      </li>
      <li className="nav-item">
       <span className="btn nav-link" onClick={logoutLoc}>
        Logout
       </span>
      </li>
     </ul>
     {/* <span className="navbar-text">Logout</span> */}
    </div>
   </div>
  </nav>
 )
}

export default NavBar
