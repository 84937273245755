import { useEffect, useState } from "react"
import axios from "axios"
import { Link } from "react-router-dom"

function PagesListPage() {
 const [list, setList] = useState([])

 useEffect(() => {
  const getPageList = async () => {
   const { data } = await axios.get(
    process.env.REACT_APP_BACKEND_URL + "/pages/list"
   )
   if (data.error === false && data.pages) {
    setList(data.pages)
   }
  }
  getPageList()
 }, [])

 if (list.length === 0) {
  return <h1>Duomenų nėra</h1>
 }

 return (
  <table class="table">
   <thead>
    <tr>
     <th scope="col">#</th>
     <th scope="col">name</th>
     <th scope="col">code</th>
     <th scope="col">description</th>
     <th scope="col">action</th>
    </tr>
   </thead>
   <tbody>
    {list.map((el) => (
     <tr>
      <th scope="row">{el.id}</th>
      <td>{el.name}</td>
      <td>{el.code}</td>
      <td>{el.comment}</td>
      <td>
       <Link to={`/page/${el.id}/edit`}>[edit]</Link>
       [delete]
      </td>
     </tr>
    ))}
   </tbody>
  </table>
 )
}

export default PagesListPage
