import { useEffect, useState } from "react"
import axios from "axios"
import {
 BrowserRouter as Router,
 Switch,
 Route,
 Redirect,
} from "react-router-dom"
//Component
import LoginForm from "./component/LoginForm/LoginForm"
import CreateUser from "./component/CreateUser/CreateUser"
import MainPage from "./component/MainPage/MainPage"
import NavBar from "./component/NavBar/NavBar"
import PagesListPage from "./component/PagesListPage/PagesListPage"
import EditPage from "./component/EditPage/EditPage"

axios.interceptors.request.use(
 (config) => {
  const { origin } = new URL(config.url)
  const allowedOrigins = [process.env.REACT_APP_BACKEND_URL]
  const token = localStorage.getItem("token")
  if (allowedOrigins.includes(origin)) {
   config.headers.authorization = `Bearer ${token}`
  }
  return config
 },
 (error) => {
  return Promise.reject(error)
 }
)

function App() {
 const storedJwt = localStorage.getItem("token")
 const [jwt, setJwt] = useState(storedJwt || null)

 // useEffect(() => {
 //  const getCsrfToken = async () => {
 //   const { data } = await axios.get(
 //    process.env.REACT_APP_BACKEND_URL + "/csrf-token"
 //   )
 //   axios.defaults.headers.post["X-CSRF-Token"] = data.csrfToken
 //  }
 //  getCsrfToken()
 // }, [])

 return (
  <Router>
   <Switch>
    <Route path="/user/create">
     <CreateUser />
    </Route>
    <Route path="/login">
     {jwt !== null ? (
      <Redirect to="/" />
     ) : (
      <LoginForm setJwt={(token) => setJwt(token)} />
     )}
    </Route>

    <Route path="/pages">
     {jwt ? (
      <>
       <NavBar logout={() => setJwt(null)} />
       <PagesListPage />
      </>
     ) : (
      <Redirect to="/login" />
     )}
    </Route>
    <Route path="/page/:id">
     {jwt ? (
      <>
       <NavBar logout={() => setJwt(null)} />
       <EditPage />
      </>
     ) : (
      <Redirect to="/login" />
     )}
    </Route>
    <Route path="/">
     {jwt ? (
      <>
       <NavBar logout={() => setJwt(null)} />
       <MainPage />
      </>
     ) : (
      <Redirect to="/login" />
     )}
    </Route>
   </Switch>
  </Router>
 )
}

export default App
