import axios from "axios"
import { useState } from "react"
import { Redirect } from "react-router-dom"

function LoginForm({ setJwt }) {
 const [email, setEmail] = useState("")
 const [password, setPassword] = useState("")

 function sendRequest(event) {
  event.preventDefault()
  axios
   .post(process.env.REACT_APP_BACKEND_URL + "/login", {
    email,
    password,
   })
   .then((res) => {
    if (res?.data) {
     const { error, message, token } = res.data
     if (error) {
      alert(message)
     } else {
      if (token) {
       localStorage.setItem("token", token)
       setJwt(token)
      }
     }
    }
   })
   .catch((error) => alert(error))
 }

 return (
  <div className="container">
   <div className="row justify-content-md-center">
    <div className="col-md-auto border rounded m-5 p-5">
     <form onSubmit={sendRequest}>
      <div className="mb-3">
       <label forhtml="inputEmail" className="form-label">
        Email address
       </label>
       <input
        type="email"
        className="form-control"
        id="inputEmail"
        aria-describedby="emailHelp"
        value={email}
        required={true}
        onChange={(input) => setEmail(input.target.value)}
       />
       <div id="emailHelp" className="form-text">
        We'll never share your email with anyone else.
       </div>
      </div>
      <div className="mb-3">
       <label forhtml="exampleInputPassword1" className="form-label">
        Password
       </label>
       <input
        type="password"
        className="form-control"
        id="exampleInputPassword1"
        autoComplete="on"
        value={password}
        required={true}
        onChange={(input) => setPassword(input.target.value)}
       />
      </div>
      <button type="submit" className="btn btn-primary">
       Submit
      </button>
     </form>
    </div>
   </div>
  </div>
 )
}

export default LoginForm
