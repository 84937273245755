import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import axios from "axios"

export default function EditPage() {
 const [pageData, setPageData] = useState(null)
 const { id } = useParams()

 useEffect(() => {
  const getPageData = async (id) => {
   const { data } = await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/edit/page/${id}`
   )
   if (data.error === false && data.page) {
    setPageData(data.page)
   }
  }
  getPageData(id)
  // eslint-disable-next-line
 }, [])

 async function updatePage(data) {
  axios
   .post(process.env.REACT_APP_BACKEND_URL + "/update/page", {
    ...data,
   })
   .then((res) => {
    if (res?.data?.page) {
     const { error, message, page } = res.data
     if (error) {
      alert(message)
     } else {
      if (page) {
       setPageData(page)
       alert(message)
      }
     }
    }
   })
   .catch((error) => alert(error))
 }

 // return <p>edit {id}</p>
 return (
  <div class="container">
   {pageData ? (
    <div class="row">
     <div className="mt-3 mb-3"> Redaguojamas įrašas nr. {pageData.id}</div>
     <div class="mb-3">
      <label forhtml="pageName" class="form-label">
       Page name
      </label>
      <input
       type="text"
       class="form-control"
       id="pageName"
       value={pageData.name}
       onChange={(e) => {
        setPageData({ ...pageData, name: e.target.value })
       }}
      />
     </div>
     <div class="mb-3">
      <label forhtml="pageTitle" class="form-label">
       Page title
       <strong>
        *<sup>1</sup>
       </strong>
      </label>
      <input
       type="text"
       class="form-control"
       id="pageTitle"
       value={pageData.title}
       onChange={(e) => {
        setPageData({ ...pageData, title: e.target.value })
       }}
      />
     </div>
     <div class="mb-3">
      <label forhtml="pageCode" class="form-label">
       Page code
       <strong>
        *<sup>2</sup>
       </strong>
      </label>
      <input
       type="text"
       class="form-control"
       id="pageCode"
       value={pageData.code}
       onChange={(e) => {
        setPageData({ ...pageData, code: e.target.value })
       }}
      />
     </div>
     <div class="mb-3">
      <label forhtml="pageMeta" class="form-label">
       Page meta tags{" "}
       <strong>
        *<sup>1</sup>
       </strong>
      </label>
      <input
       type="text"
       class="form-control"
       id="pageMeta"
       value={pageData.metaTags}
       onChange={(e) => {
        setPageData({ ...pageData, metaTags: e.target.value })
       }}
      />
     </div>
     <div class="mb-3">
      <label forhtml="pageDescription" class="form-label">
       Page description
       <strong>
        *<sup>1</sup>
       </strong>
      </label>
      <textarea
       class="form-control"
       id="pageDescription"
       rows="3"
       onChange={(e) => {
        setPageData({ ...pageData, description: e.target.value })
       }}
      >
       {pageData.description}
      </textarea>
     </div>
     <div class="mb-3">
      <label forhtml="pageContent" class="form-label">
       Page content
       <strong>
        *<sup>3</sup>
       </strong>
      </label>
      <textarea
       class="form-control"
       id="pageContent"
       rows="30"
       onChange={(e) => {
        setPageData({ ...pageData, content: e.target.value })
       }}
      >
       {pageData.content}
      </textarea>
     </div>
     <div class="mb-3">
      <label forhtml="pageComment" class="form-label">
       Page comment
      </label>
      <textarea
       class="form-control"
       id="pageComment"
       rows="3"
       onChange={(e) => {
        setPageData({ ...pageData, comment: e.target.value })
       }}
      >
       {pageData.comment}
      </textarea>
     </div>
     <div class="d-grid gap-2 d-md-flex justify-content-md-end">
      <button
       class="btn btn-primary"
       type="button"
       onClick={() => updatePage(pageData)}
      >
       Update
      </button>
     </div>

     <div className="pt-3">
      <strong>
       *<sup>1</sup>
      </strong>{" "}
      - for header
     </div>
     <div>
      <strong>
       *<sup>2</sup>
      </strong>{" "}
      - "hardcoded" (do not change if you do not know where to use)
     </div>
     <div>
      <strong>
       *<sup>3</sup>
      </strong>{" "}
      - page content (HTML)
     </div>
    </div>
   ) : (
    <p>Duomenys kraunami</p>
   )}
  </div>
 )
}
